import gsap from 'gsap';

function init() {
    const solutions = document.querySelector('.js-solutions');
    if (solutions) {
        gsap.to(solutions, {
            scrollTrigger: {
                scroller: document.querySelector('.scroll-wrapper'),
                trigger: solutions,
                start: '100% 100%',
                end: () => '+=' + window.innerHeight,
                scrub: 0,
                invalidateOnRefresh: true,
            },
            scaleX: () => 0.97,
            force3D: false,
            ease: 'none',
        });

        const solutionsBg = solutions.querySelector('.js-solutions-bg');
        if (solutionsBg) {
            const solutionsCircle = solutionsBg.querySelector('.js-solutions-circle');
            if (solutionsCircle) {
                gsap.to(solutionsCircle, {
                    scrollTrigger: {
                        scroller: document.querySelector('.scroll-wrapper'),
                        trigger: solutionsBg,
                        start: '0% 100%',
                        end: '100% 100%',
                        scrub: 0,
                    },
                    scale: 2.84,
                    force3D: false,
                    ease: 'none',
                });
            }
        }
    }
}

export default { init };
