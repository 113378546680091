import { Fancybox } from '@fancyapps/ui/src/Fancybox/Fancybox.js';
import ru from '@fancyapps/ui/src/Fancybox/l10n/ru';

function init() {
    if (document.querySelector('[data-fancybox]')) {
        Fancybox.bind('[data-fancybox]', {
            closeButton: 'outside',
            l10n: ru,
            template: {
                closeButton: `<span class="carousel__close"></span>`,
            },
        });
    }

    function modalHash() {
        if (window.location.hash) {
            if (
                document.querySelector(window.location.hash) &&
                document.querySelector(window.location.hash).classList.contains('modal')
            ) {
                Fancybox.show(
                    [
                        {
                            src: window.location.hash,
                        },
                    ],
                    {
                        closeButton: 'outside',
                        l10n: ru,
                        template: {
                            closeButton: `<span class="carousel__close"></span>`,
                        },
                    },
                );
            }
        }
    }

    modalHash();

    window.addEventListener('hashchange', modalHash);
}

export default { init };
