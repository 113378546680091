function init() {
    const queryParams = (new URL(document.location.href)).searchParams;
    interface IParams {
        type: string,
        value: string,
    }
    const params = {
        type: queryParams.get('type') ?? '',
        value: queryParams.get('value') ?? '',
    }
    if(params.type && params.type === 'fancybox' && params.value.length > 0){
        document.querySelector(`[href="#${params.value}"]`).click()
    }
}
export default { init };
