import Splitting from 'splitting';

function init() {
    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('is-visible');
                    observer.unobserve(entry.target);
                }
            });
        },
        { rootMargin: '0% 0% 0% 0%', threshold: [0, 1] },
    );

    const split = document.querySelectorAll('.js-split');
    if (split.length > 0) {
        split.forEach((element) => {
            new Splitting({
                target: element,
                by: 'words',
            });
            observer.observe(element);
        });
    }
}

export default { init };
