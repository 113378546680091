import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

function init() {
    const marquee = document.querySelectorAll('.js-reviews-marquee');
    if (marquee.length > 0) {
        marquee.forEach((element) => {
            const anim = gsap.to(element, {
                repeat: -1,
                paused: true,
                x: -element.offsetWidth,
                duration: element.offsetWidth / 100,
                force3D: false,
                ease: 'none',
            });
            ScrollTrigger.create({
                scroller: document.querySelector('.scroll-wrapper'),
                trigger: element,
                onEnter: () => anim.play(),
                onEnterBack: () => anim.play(),
                onLeave: () => anim.pause(),
                onLeaveBack: () => anim.pause(),
            });
        });
    }
}

export default { init };
