import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

function init() {
    const sticky = document.querySelectorAll('.js-sticky');
    if (sticky.length > 0) {
        sticky.forEach((element) => {
            gsap.to(element, {
                scrollTrigger: {
                    scroller: document.querySelector('.scroll-wrapper'),
                    trigger: element,
                    start: '0% 0%',
                    end: () => '+=' + window.innerHeight,
                    scrub: 0,
                    invalidateOnRefresh: true,
                },
                y: () => 0,
                force3D: false,
                ease: 'none',
            });
        });
    }

    const stickyBottom = document.querySelectorAll('.js-sticky-bottom');
    if (stickyBottom.length > 0) {
        stickyBottom.forEach((element) => {
            gsap.to(element, {
                scrollTrigger: {
                    scroller: document.querySelector('.scroll-wrapper'),
                    trigger: element,
                    start: '100% 100%',
                    end: () => '+=' + element.offsetHeight,
                    scrub: 0,
                    invalidateOnRefresh: true,
                },
                y: () => 0,
                force3D: false,
                ease: 'none',
            });
        });
    }

    const stickyHorizontal = document.querySelectorAll('.js-sticky-horizontal');
    if (stickyHorizontal.length > 0) {
        stickyHorizontal.forEach((element) => {
            ScrollTrigger.matchMedia({
                '(min-width: 1201px)': function () {
                    gsap.set(element, {
                        marginBottom: () => element.offsetWidth - window.innerWidth,
                    });
                    gsap.to(element, {
                        scrollTrigger: {
                            scroller: document.querySelector('.scroll-wrapper'),
                            trigger: element,
                            start: '100% 100%',
                            end: () => '+=' + (element.offsetWidth - window.innerWidth),
                            scrub: 0,
                            invalidateOnRefresh: true,
                        },
                        x: () => -1 * (element.offsetWidth - window.innerWidth),
                        y: () => element.offsetWidth - window.innerWidth,
                        force3D: false,
                        ease: 'none',
                    });
                },
                '(max-width: 1200px)': function () {
                    gsap.set(element, {
                        marginBottom: () => 0,
                        x: () => 0,
                        y: () => 0,
                    });
                },
            });
        });
    }
}

export default { init };
