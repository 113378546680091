import IMask from 'imask';

function init() {
    const phone = document.querySelectorAll('.js-phone-mask');
    if (phone.length > 0) {
        phone.forEach((element) => {
            IMask(element, {
                mask: '+{7} 000 000-00-00',
            });
        });
    }
}

export default { init };
