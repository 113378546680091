function init() {
    const observer = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('is-visible');
                    observer.unobserve(entry.target);
                }
            });
        },
        { rootMargin: '0% 0% 0% 0%', threshold: [0, 1] },
    );

    const fade = document.querySelectorAll('.js-fade');
    if (fade.length > 0) {
        fade.forEach((element) => {
            observer.observe(element);
        });
    }
}

export default { init };
