import Glide, { Controls, Breakpoints, Swipe } from '@glidejs/glide/dist/glide.modular.esm';

function init() {
    const slider = document.querySelector('.js-reviews-slider');
    if (slider) {
        new Glide(slider, {
            type: 'carousel',
            perView: 1,
            focusAt: 'center',
            gap: 20,
            breakpoints: {
                992: {
                    gap: 10,
                },
            },
        }).mount({ Controls, Breakpoints, Swipe });
    }
}

export default { init };
