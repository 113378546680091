import Scrollbar, { ScrollbarPlugin } from 'smooth-scrollbar';
import ScrollTrigger from 'gsap/ScrollTrigger';

class AnchorPlugin extends ScrollbarPlugin {
    static pluginName = 'anchor';

    onHashChange = () => {
        this.jumpToHash(window.location.hash);
    };

    onClick = (event) => {
        const { target } = event;

        if (target.tagName !== 'A') {
            return;
        }

        const hash = target.getAttribute('href');

        if (!hash || hash.charAt(0) !== '#') {
            return;
        }

        this.jumpToHash(hash);
    };

    jumpToHash = (hash) => {
        const { scrollbar } = this;

        if (!hash) {
            return;
        }

        // reset scrollTop
        scrollbar.containerEl.scrollTop = 0;

        scrollbar.scrollTo(0, document.querySelector(hash).offsetTop, 0);
    };

    onInit() {
        this.jumpToHash(window.location.hash);

        window.addEventListener('hashchange', this.onHashChange);

        this.scrollbar.contentEl.addEventListener('click', this.onClick);
    }

    onDestory() {
        window.removeEventListener('hashchange', this.onHashChange);

        this.scrollbar.contentEl.removeEventListener('click', this.onClick);
    }
}
class DisableScrollPlugin extends ScrollbarPlugin {
    static pluginName = 'disableScroll';

    static defaultOptions = {
        direction: '',
    };

    transformDelta(delta) {
        if (this.options.direction) {
            delta[this.options.direction] = 0;
        }

        return { ...delta };
    }
}

function init() {
    Scrollbar.use(AnchorPlugin);
    Scrollbar.use(DisableScrollPlugin);

    if (document.querySelector('.scroll-wrapper')) {
        window.scrollbar = Scrollbar.init(document.querySelector('.scroll-wrapper'), {
            plugins: {
                disableScroll: {
                    direction: 'x',
                },
            },
            renderByPixels: false,
        });
    }

    window.scrollbar.addListener(ScrollTrigger.update);
    ScrollTrigger.scrollerProxy(document.querySelector('.scroll-wrapper'), {
        scrollTop(value) {
            if (arguments.length) {
                window.scrollbar.scrollTop = value;
            }
            return window.scrollbar.scrollTop;
        },
        scrollLeft(value) {
            if (arguments.length) {
                window.scrollbar.scrollLeft = value;
            }
            return window.scrollbar.scrollLeft;
        },
        getBoundingClientRect() {
            return { top: 0, left: 0, width: window.innerWidth, height: window.innerHeight };
        },
        pinType: document.querySelector('.scroll-wrapper').style.transform ? 'transform' : 'fixed',
    });

    const scrollTo = document.querySelectorAll('.js-scroll-to');
    if (scrollTo.length > 0) {
        scrollTo.forEach((element) => {
            element.addEventListener('click', (e) => {
                e.preventDefault();
                let hash = element.getAttribute('href');
                if (hash) {
                    let slider = document.getElementById(hash.substring(1));
                    if (slider) {
                        scrollbar.scrollTo(0, slider.offsetTop, 2000);
                    }
                }
            });
        });
    }
}

function refresh() {
    ScrollTrigger.addEventListener('refresh', () => window.scrollbar.update());
    ScrollTrigger.refresh();
}

function update() {
    window.scrollbar.update();
    ScrollTrigger.refresh();
}

export default { init, refresh, update };
