import LazyLoad from 'vanilla-lazyload';

function init() {
    if (document.querySelector('.js-lazy')) {
        window.lazyInstance = new LazyLoad({
            use_native: true,
            elements_selector: '.js-lazy',
            threshold: 1000,
        });
    }
}

export default { init };
