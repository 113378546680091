import $ from 'jquery';

function init() {
    $(document).on('submit', '[data-ajax-form]', function (e) {
        e.preventDefault();

        if (0 === validateForm($(this))) return false;

        var formData = new FormData($(this)[0]);

        window.sendAjax(formData, $(this));
    });

    function validateForm(form) {
        $(form).find('.is-error').removeClass('is-error');
        var send = 1;
        $(form)
            .find('[data-required]')
            .each(function () {
                if ($(this).val() === '') {
                    $(this).addClass('is-error');
                    send = 0;
                }
            });

        return send;
    }

    var sendAjax = (window.sendAjax = function (data, container) {
        $.ajax({
            type: 'POST',
            url: '/smm/ajax/',
            data: data,
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            success: function (data) {
                container.closest('.request__col').find('.request__descr').hide();
                container.html(
                    '<div class="request__message text-12">Ваше сообщение успешно отправлено. Наш менеджер свяжется с Вами в ближайшее время.</div>',
                );
            },
        });
    });
}

export default { init };
